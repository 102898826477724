import React from 'react';
import { Link } from 'gatsby';
import { Location } from "@reach/router"
import styled from 'styled-components';
import queryString from 'query-string'


const BackToCourierLink = styled(Link)`
    font-family: 'PF BeauSans Pro', -apple-system, sans-serif;
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    line-height: 1.56;
    letter-spacing: normal;
    color: ${props => props.theme.colors.blues.peacock};
    display: inline-flex;
    padding: 10px 50px;
    background-color: ${props => props.theme.colors.maize};
    :hover {
    color: ${props => props.theme.colors.blues.peacock};
    }
`;

const BackToCourierLinkWithLocation = () => (
<Location >
  {({ location }) => {
    const query = location.search ? queryString.parse(location.search): {}
    return( 
    <BackToCourierLink
      to={`/courier/?stationId=${query.stationId}`}
      activeClassName="pos-navbar-active-link"
      partiallyActive={true}
    >
          ΕΙΣΑΙ COURIER; ΠΑΤΗΣΤΕ ΕΔΩ
    </BackToCourierLink>
  )}
  }
  </Location>)

export default BackToCourierLinkWithLocation