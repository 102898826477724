import React from 'react';
import { Box, Button, Text, Flex, Image } from 'rebass';
import { Link } from 'gatsby';

import { Container } from '../../../components/container';
import WeBoxitQRCode from '../../images/weboxit_qrcode.png';
import { BackToCourierLink } from '../../components/Buttons'

const HeroCardGetAccess = () => (
  <Box width="376px" ml="auto">
    <Box
      bg="maize"
      css={{
        borderRadius: '16px',
      }}
    >
      <Flex px="28px" pt="22px" flexDirection="column" alignItems="center">
        <Text
          pt="10px"
          pb="24px"
          color="blues.peacock"
          fontSize="17px"
          letterSpacing="-0.12px"
          textAlign="center"
        >
          ΣΥΝΔΕΘΕΙΤΕ ΣΤΗΝ ΥΠΗΡΕΣΙΑ
        </Text>

        <Image pb="10px" src={WeBoxitQRCode} />

        <Flex pt="18px" flexDirection="column">
          <Text
            color="blues.peacock"
            fontSize="15px"
            lineHeight="1.33"
            letterSpacing="0.35px"
            textAlign="center"
            pb="20px"
          >
            Επισκεφθείτε τη σελίδα weboxit.com από ποιαδήποτε φορητή συσκευή ή
            Η/Υ!
          </Text>
          <Box pb="25px">
            {/* <Link to="/pos/why_weboxit/4_steps_tutorial"> */}
            <Button width="100%" py="20px" bg="blues.peacock">
              <Text
                fontSize="12px"
                fontWeight="900"
                lineHeight="1.75"
                letterSpacing="2px"
              >
                {' '}
                ΤΙ ΕΙΝΑΙ ΤΟ WEBOXIT
              </Text>
            </Button>
            {/* </Link> */}
          </Box>
          <Box pb="25px">
            {/* <Link to="/pos/why_weboxit/smartest_box_delivery"> */}
            <Button width="100%" py="20px" bg="blues.peacock">
              <Text
                fontSize="11px"
                fontWeight="900"
                lineHeight="1.75"
                letterSpacing="2px"
              >
                ΧΡΗΣΙΜΟΠΟΙΕΙΣΤΕ ΑΥΤΟ ΤΟ ΣΤΑΘΜΟ
              </Text>
            </Button>
            {/* </Link> */}
          </Box>
        </Flex>
      </Flex>
    </Box>
  </Box>
);

const HeroTitle = ({ children }) => (
  <Text
    p="0"
    pt="120px"
    pb="50px"
    color="white"
    fontSize="29px"
    fontWeight="600"
    lineHeight="0.86"
    letterSpacing="normal"
  >
    {children}
  </Text>
);

const HeroSubtitle = ({ children }) => (
  <Text
    m="0"
    pb={['21px', 4, 5]}
    color="white"
    fontSize="52px"
    fontWeight="900"
    lineHeight="1.1"
    letterSpacing="-1.91px"
  >
    {children}
  </Text>
);

const VerticalYellowLine = () => (
  <Box
    m="0"
    py="21px"
    width="12px"
    height="504px"
    bg="maize"
    css={{
      borderRadius: '14px',
      display: 'inline-block',
    }}
  />
);

const HeroHeader = ({ title, subtitle, children }) => (
  <>
    <Flex flexWrap="wrap" flexDirection="row" width={1}>
      <VerticalYellowLine />
      <Flex
        width={[0.5, 0.55, 0.9]}
        pl="40px"
        flexWrap="wrap"
        flexDirection="column"
      >
        <HeroTitle>{title}</HeroTitle>
        <HeroSubtitle>{subtitle}</HeroSubtitle>
        <BackToCourierLink />
      </Flex>
      {children}
    </Flex>
  </>
);

const Hero = ({ title, subtitle }) => (
  <>
    <Container>
      <Flex
        pt="18px"
        flexWrap="wrap"
        flexDirection="row"
        alignItems="flex-start"
      >
        <HeroHeader title={title} subtitle={subtitle}>
          <HeroCardGetAccess />
        </HeroHeader>
      </Flex>
    </Container>
  </>
);

export default Hero;
