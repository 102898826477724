import React from 'react';
import { Box, Heading, Text } from 'rebass';
import queryString from 'query-string'
import { useQuery } from '@apollo/react-hooks';
import { gql  } from 'apollo-boost';

import { Layout } from '../../pos/layout';
import { Hero } from '../../pos/components/Hero';

const GET_STATION_BY_ID = gql`
  query GetStationById($stationId: ID!) {
    findStationByID(id: $stationId) {
      _id
      name
      pin
    }
  }
`;


const StationInfo =({stationId})=>{
  const { loading, error, data, refetch } = useQuery(GET_STATION_BY_ID, {
    variables: { stationId },
  });

  if(loading){
    return (<Box>
      <Text
          fontSize="22px"
          fontWeight="600"
          lineHeight="1.5"
          color="blues.peacock"
        >Searching For Station</Text>
    </Box>);
  }

  if(error){
    return (<Box>
      <Text
          fontSize="22px"
          fontWeight="600"
          lineHeight="1.5"
          color="blues.peacock"
        >Error Searching Station</Text>
    </Box>);
  }

  if(data.findStationByID){
    return (<Box>
      <Text
          fontSize="22px"
          fontWeight="600"
          lineHeight="1.5"
          color="blues.peacock"
        >{data.findStationByID.name}</Text>
    </Box>)
  }

  return (<Box>
    <Text
        fontSize="22px"
        fontWeight="600"
        lineHeight="1.5"
        color="blues.peacock"
      >No Station</Text>
  </Box>);
}

const HomePage = (props) => {
  const query = props.location.search ? queryString.parse(props.location.search): {}

  return(
    <Layout
      footer={() => (
        <>
          {query.stationId && <StationInfo stationId={query.stationId} />}
          <Box mx="auto" />
          <Heading fontSize="40px" lineHeight="0.63" color="blues.peacock">
            weboxit.com
          </Heading>
        </>
      )}
    >
      <Hero
        title="Καλώς ορίσατε στο WEBOXIT!"
        subtitle="Ο πιο εύκολος τρόπος μεταφοράς δεμάτων στην Ελλάδα."
      />
    </Layout>
    )
}

export default HomePage;
