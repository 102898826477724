
import React from 'react';
import { Button, Text,  } from 'rebass';


const FooterButton = ({ onClick, children }) => (
    <Button py="24px" px="60px" bg="blues.peacock" mr="18px" onClick={onClick}>
      <Text fontSize="14px" fontWeight="bold" letterSpacing="2.34px">
        {children}
      </Text>
    </Button>
  );

export default FooterButton

