import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Flex, Box } from 'rebass';
import queryString from 'query-string'
import { useQuery } from '@apollo/react-hooks';
import { gql  } from 'apollo-boost';
import { Location } from "@reach/router"

import BoxitLogo from '../../../components/Logo/BoxitLogo';

export const NavLink = styled(Link)`
  height: 25px;
  font-family: 'PF BeauSans Pro', -apple-system, sans-serif;
  font-size: 16px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #ffffff;
  display: inline-flex;
  padding-left: 52px;
  padding-top: 4px;
  :hover {
    color: ${props => props.theme.colors.maize};
  }
`;

const GET_STATION_BY_ID = gql`
  query GetStationById($stationId: ID!) {
    findStationByID(id: $stationId) {
      _id
      name
      pin
    }
  }
`;


export const Navbar = () => (
  <Location >
    {({ location }) => ( <NavbarComp location={location} />)}
</Location>)

export const NavbarComp = ({location}) => {
const query = location.search ? queryString.parse(location.search): {}


const { loading, error, data, refetch } = useQuery(GET_STATION_BY_ID, {
  variables: { stationId: query.stationId },
});

const station = (data && data.findStationByID) ? data.findStationByID : {}; 

const isActive = ({ location, href })=>{

  const isCrnt = location && location.href && location.href.includes(href);
  if(isCrnt){
    return {
      style: {
        color: "#f4d448"
      }
    };
  }
  return null;
}

return (
    <Flex bg="blues.peacock">
      <Box px="30px" width="100%">
        <Flex mx={'auto'} py="42px" alignItems="center">
          <NavLink 
            to={`/pos/?stationId=${query.stationId}`} 
            activeClassName="pos-navbar-active-link"
            partiallyActive={true}
            getProps={isActive}
          >
            ΑΡΧΙΚΗ
          </NavLink>
          <NavLink 
            to={`/pos/send_a_box/?stationId=${query.stationId}`} 
            activeClassName="pos-navbar-active-link"
            partiallyActive={true}
            state={{ station: station  }}
            getProps={isActive}
          >
            ΑΠΟΣΤΟΛΗ ΔΕΜΑΤΟΣ
          </NavLink>
          <NavLink
            to={`/pos/pickup_your_box?stationId=${query.stationId}`}
            activeClassName="pos-navbar-active-link"
            partiallyActive={true}
            state={{ station: station  }}
            getProps={isActive}
          >
            ΠΑΡΑΛΑΒΗ ΔΕΜΑΤΟΣ
          </NavLink>
          {/* <NavLink
            to="/pos/why_we_boxit"
            activeClassName="pos-navbar-active-link"
          >
            ΤΙ ΕΙΝΑΙ ΤΟ WEBOXIT
          </NavLink> */}
          <Box mx="auto" />
          <BoxitLogo url={`/pos/?stationId=${query.stationId}`} height="24px" />
        </Flex>
      </Box>
    </Flex>
  );
}
